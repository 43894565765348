<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Subheader"
                  v-model="intro.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Desc"
                  v-model="intro.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <!--                <va-input-->
                <!--                  label="trailingText"-->
                <!--                  v-model="intro.trailingText"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-button :to="{ name: 'ms-quotes-edit' }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="quoteFields"
                  :data="quotes"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('quotes',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-quotes-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeQuotes(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Content Header"
                  v-model="textContent.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-button :to="{ name: 'ms-contents-edit' }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="companyContentsFields"
                  :data="contents"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('contents',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-contents-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeContents(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Stats Header"
                  v-model="stats.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-data-table
                  :fields="statsFields"
                  :data="statsList"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-stats-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    // ckeditor: CKEditor.component,
  },
  data () {
    return {
      intro: {
        desc: '',
        header: '',
        img: '',
        subheader: '',
      },
      textContent: {
        header: '',
      },
      stats: {
        header: '',
      },
      history: null,
      missions: null,
      principles: null,

      quotes: [],
      statsList: [],

      header: '',
      subheader: '',
      desc: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      contents: null,

      img: [],
      imgId: '',
      imgNew: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Quote', 'Content'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    togglePublished (route, id) {
      axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/${route}/${id}`, {
        published: !!this.news.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async removeQuotes (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/quotes/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
          this.fetch()
        })
    },
    async removeContents (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/contents/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
          this.fetch()
        })
    },
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imgNew = response.data.patch
          this.imgExisting = `${process.env.VUE_APP_MS_DEV_URL}/${this.imgNew}`
          this.imgId = response.data.id
          this.intro.img = response.data.patch
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        // .catch(() => {
        //   this.showToast('Error')
        // })
    },
    submit () {
      const data = {
        intro: this.intro,
        textContent: this.textContent,
        stats: this.stats,
      }
      console.log(data)

      axios.put(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/ceo`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/ceo`)
        .then(response => {
          this.intro = response.data.intro
          this.textContent = response.data.textContent
          this.stats = response.data.stats
          this.statsList = response.data.statsList
          this.quotes = response.data.quotes
          this.numList = response.data.numList
          this.contents = response.data.contents
          this.imgNew = response.data.intro.img
          this.imgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.intro.img
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    companyContentsFields () {
      return [
        {
          name: 'desc',
          title: 'Desc',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    statsFields () {
      return [
        {
          name: 'desc',
          title: 'Desc',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    numListFields () {
      return [
        {
          name: 'header',
          title: 'Header',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    tabFields () {
      return [
        {
          name: 'key',
          title: 'Key',
          width: '40%',
        },
        {
          name: 'content',
          title: 'Content',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    quoteFields () {
      return [
        {
          name: 'content',
          title: 'Content',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
